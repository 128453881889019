exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4C97FF */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ .RobboMenu_robbo_menu_3gKDu{\n\n  position: absolute;\n  /*top:4.8%;*/\n  top:48px;\n  left:355px;\n/*  background-color: rgb(90,90,90);*/\n  background-color: #00af41;\n\n  border: 0.2px solid rgba(0,0,0,0.5);\n  border-bottom-right-radius: 10px;\n  border-bottom-left-radius: 10px;\n\n\n\n  -webkit-box-shadow: 0px 6px 10px rgba(0,0,0,0.5);\n\n\n\n          box-shadow: 0px 6px 10px rgba(0,0,0,0.5);\n\n  /*color:#383838;*/\n  color:white;\n\n  font-weight:bold;\n\n  font-size: 10px;\n\n} .RobboMenu_robbo_menu_item_2F5ja{\n\n      margin-top: 7px;\n      margin-bottom: 7px;\n      margin-left: 3px;\n      margin-right: 3px;\n\n      padding-left: 5px;\n      padding-right: 5px;\n\n     font-size: 13px;\n} .RobboMenu_robbo_menu_item_2F5ja:hover{\n\n      background-color:  hsla(0, 0%, 0%, 0.15);\n\n} .RobboMenu_robbo_menu_show_3m1l2{\n\n    display: block;\n} .RobboMenu_robbo_menu_hidden_1WQab{\n\n\n    display: none;\n} .RobboMenu_hrDevider_2Wd_m{\n\n        color:#383838;\n        background-color: #383838;\n        border: 0.2px solid #383838;\n}\n", ""]);

// exports
exports.locals = {
	"robbo_menu": "RobboMenu_robbo_menu_3gKDu",
	"robboMenu": "RobboMenu_robbo_menu_3gKDu",
	"robbo_menu_item": "RobboMenu_robbo_menu_item_2F5ja",
	"robboMenuItem": "RobboMenu_robbo_menu_item_2F5ja",
	"robbo_menu_show": "RobboMenu_robbo_menu_show_3m1l2",
	"robboMenuShow": "RobboMenu_robbo_menu_show_3m1l2",
	"robbo_menu_hidden": "RobboMenu_robbo_menu_hidden_1WQab",
	"robboMenuHidden": "RobboMenu_robbo_menu_hidden_1WQab",
	"hrDevider": "RobboMenu_hrDevider_2Wd_m"
};